<template>
    <div class="mentality">
        <div class="mentality-top">
            <div class="logo" @click="goto('/')">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
            <div class="titlebox">心理科普</div>
        </div>
        <div class="contentbox">
            <div class="left-con">
                <div class="ltop">
                    <div class="titlebox">心理热线</div>
                    <div class="txt">{{base.tsXinli}}</div>
                    <div class="tel">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/phone.png)"></div>
                        </div>
                        <div class="t1">{{base.webTel}}（市级心理热线）</div>
                    </div>
                </div>
                <div class="lbottom">
                    <div class="titlebox">专科门诊时间</div>
                    <div class="txt" v-html="base.sjMenZhen"></div>
                    <!-- <div class="txt">
                        <div class="t1">
                            <div class="day">周一<span></span>~<span></span>周五：</div>
                             <div class="time">
                                上午8:00~11:30<br>下午13:30~17:00
                             </div>
                        </div>
                        <div class="t2"><div class="day">周<span></span>六：</div><div class="time">上午8:00~11:30</div></div>
                        <div class="t3">挂号上午11:15结束，下午16:45结束</div>
                    </div> -->
                </div>
            </div>
            <div class="right-con">
                <div class="platelist">
                    <div class="plate">
                        <div class="titlebox" @click="goto('/mentality/videolist', {cateName: '科普视频', openKey: 2})">
                            <div class="atitle">科普视频</div>
                            <div class="btitle">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/more.png)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="itemlist">
                            <div class="item" v-for="(item, index) in video" :key="index" @click="goto('/compositionShow', {id: item.id})">
                                <div class="img">
                                    <div class="picbox">
                                        <div class="pic" :style="{backgroundImage: 'url(' + baseUrl + item.imgUrl +')'}"></div>
                                    </div>
                                    <div class="shadow">
                                        <div class="play">
                                            <div class="picbox">
                                                <div class="pic" style="background-image: url(/img/play.png)"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="itemtitle">
                                    <div class="atitle">{{item.title}}</div>
                                    <div class="time">{{item.updateTime}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="plate">
                        <div class="titlebox" @click="goto('/mentality/mangalist', {cateName: '科普漫画', openKey: 1})">
                            <div class="atitle">科普漫画</div>
                            <div class="btitle">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/more.png)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="itemlist">
                            <div class="item" v-for="(item, index) in manga" :key="index" @click="goto('/compositionShow', {id: item.id})">
                                <div class="img">
                                    <div class="picbox">
                                        <div class="pic" :style="{backgroundImage: 'url(' + baseUrl + item.imgUrl +')'}"></div>
                                    </div>
                                </div>
                                <div class="itemtitle">
                                    <div class="atitle">{{item.title}}</div>
                                    <div class="time">{{item.updateTime}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="plate">
                        <div class="titlebox" @click="goto('/mentality/compositionlist', {cateName: '科普文章', openKey: 0})">
                            <div class="atitle">科普文章</div>
                            <div class="btitle">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/more.png)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="itemlist">
                            <div class="item" v-for="(item, index) in composition" :key="index" @click="goto('/compositionShow', {id: item.id})">
                                <div class="img">
                                    <div class="picbox">
                                        <div class="pic" :style="{backgroundImage: 'url(' + baseUrl + item.imgUrl +')'}"></div>
                                    </div>
                                </div>
                                <div class="itemtitle">
                                    <div class="atitle">{{item.title}}</div>
                                    <div class="time">{{item.updateTime}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="plate">
                        <div class="titlebox" @click="goto('/mentality/q&a', {cateName: '常见心理问与答', openKey: 3})">
                            <div class="atitle">常见心理问与答</div>
                            <div class="btitle">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/more.png)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="qalist">
                            <div class="item" v-for="(item, index) in qa" :key="index">
                                <div class="txt" @click="goto('/mentality/q&a', {id: item.id, openKey: 3})">{{item.title}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-qrcode">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/right_qrcode.png)"></div>
                </div>
                <img :src="baseUrl + base.wxLogo" alt="扫一扫">
            </div>
            <!-- <div class="qrcode">
                <div style=" font-weight:bold; text-align:center; font-size: 20px;color: #ffffff">
                    扫码继续
                </div>
                <div class="qr-c">
                    <div ref="qrCode"></div>
                </div>
            </div> -->
        </div>
    </div>
</template>
  
<script>
import { getBase } from "@/js/request";
import { getBookList } from "@/js/request";
import { getVideoList } from "@/js/request";
import { getMangaList } from "@/js/request";
import { getQAList } from "@/js/request";
import QRCode from 'qrcode';
import { isPhone } from "../utils/tool"

export default {
    name: 'Mentality',
    components: {

    },
    data() {
        return {
            base: {},
            video: [],
            manga: [],
            composition: [],
            qa: [],
            baseUrl: ''
        }
    },
    methods: {
        goto(adress, cate) {
            this.$router.push({path: adress, query: cate});
        }
    },
    mounted() {
        // this.$refs.qrCode.innerHTML = '';
        // QRCode.toCanvas(window.location.href, { errorCorrectionLevel: 'H', width: 100 }, (err, canvas) => {
        //     if (err) throw error
        //     this.$refs.qrCode.appendChild(canvas)
        // });
        if(isPhone()){
            this.$root.hideRight();
        }else{
            this.$root.showRight();
        }
        getBase().then(res => {
            this.base = res.data;
            this.baseUrl = res.config.baseURL;
        });
        getBookList().then(res => {
            this.composition = res.data;
        });
        getVideoList().then(res => {
            this.video = res.data;
        });
        getMangaList().then(res => {
            this.manga = res.data;
        });
        getQAList().then(res => {
            this.qa = res.data;
        });
    }
}
</script>

<style lang="scss">
@media (max-height: 1000px) {
    .mentality{
        padding: 20px 0 60px 0!important;
    }
    .mentality-top{
        margin-bottom: 20px!important;
    }
    .mentality .contentbox{
        height: 780px!important;
    }
    .mentality .contentbox .left-con .ltop{
        margin-bottom: 30px!important;
    }
}
.mentality {
    padding: 60px 0;
    box-sizing: border-box;
    max-height: 100vh;
    width: 1400px;
    margin: 0 auto;
    .mentality-top {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
        .logo {
            @include sys-picbox(438px, 80px);
            cursor: pointer;
        }
        .titlebox {
            @include sys-font(42px, #000, bold);
            margin-left: 30px;
            padding-left: 30px;
            position: relative;
            &::before {
                content: '';
                width: 2px;
                height: 46px;
                background: #000;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
    .contentbox {
        display: flex;
        width: 100%;
        height: 820px;
        position: relative;
        .left-con {
            @include sys-flex(space-between);
            width: 400px;
            height: 100%;
            margin-right: 40px;
            flex: none;
            .ltop, .lbottom {
                padding: 15px 40px;
                box-sizing: border-box;
                box-shadow: 0px 0px 50px 0px rgba(63,101,224,0.05);
                .titlebox {
                    @include sys-text-hide(1);
                    line-height: 66px;
                    margin-bottom: 24px;
                }
            }
            .ltop {
                @include sys-boxsize(100%, 300px, 20px);
                background: $acolor url(../assets/img/mentality_telbg.png) no-repeat 220px bottom/140px 140px;
                .titlebox {
                    @include sys-font(28px, #fff, bold);
                    border-bottom: 2px solid rgba(255, 255, 255, .2);
                }
                .txt {
                    @include sys-text-hide(5);
                    @include sys-font(16px, #fff);
                    line-height: 26px;
                    margin-bottom: 24px;
                }
                .tel {
                    display: flex;
                    align-items: center;
                    letter-spacing: 0;
                    @include sys-font(16px, #fff);
                    @include sys-picbox(18px, 20px);
                    .picbox {
                        margin-right: 12px;
                        flex: none;
                    }
                    .t1 {
                        @include sys-text-hide(1);
                    }
                }
            }
            .lbottom {
                @include sys-boxsize(100%, 480px, 20px);
                background: #fff url(../assets/img/mentality_timebg.png) no-repeat 152px 270px/228px 170px;
                overflow: hidden;
                .titlebox {
                    @include sys-font(28px, #000, bold);
                    border-bottom: 2px solid #EEEEEE;
                }
                .txt {
                    @include sys-font(16px, #333);
                    line-height: 26px;
                    letter-spacing: 1px;
                    white-space: pre-wrap;
                    &>div {
                        display: flex;
                        margin-bottom: 10px;
                    }
                    .day {
                        width: 100px;
                        flex: none;
                        display: flex;
                        span {
                            flex: 1;
                        }
                    }
                    .time {
                        flex: 1;
                    }
                }
            }
        }
        .right-con {
            flex: 1;
            height: 100%;
            padding: 40px 60px;
            box-sizing: border-box;
            background: #FFFFFF;
            box-shadow: 0px 0px 50px 0px rgba(63,101,224,0.05);
            border-radius: 20px;
            .platelist {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                height: 100%;
                .plate {
                    width: 390px;
                    height: 330px;
                    margin-bottom: 60px;
                    overflow: hidden;
                    .titlebox {
                        @include sys-flex(space-between, row);
                        margin-bottom: 20px;
                        cursor: pointer;
                        .atitle {
                            flex: 1;
                            @include sys-text-hide(1);
                            @include sys-font(28px, #000, bold);
                            line-height: 30px;
                        }
                        .btitle {
                            flex: none;
                            padding: 0 10px;
                            cursor: pointer;
                            @include sys-picbox(22px, 16px);
                        }
                    }
                    .qalist {
                        padding: 0 10px;
                        .item {
                            @include sys-text-hide(1);
                            @include sys-font(18px, #000);
                            line-height: 40px;
                            cursor: pointer;
                            transition: color .3s;
                            &:hover {
                                color: $acolor;
                            }
                        }
                    }
                    .itemlist {
                        width: 100%;
                        .item {
                            display: flex;
                            width: 100%;
                            height: 80px;
                            margin-bottom: 20px;
                            cursor: pointer;
                            &:hover {
                                .itemtitle {
                                    .atitle {
                                        color: $acolor;
                                    }
                                }
                            }
                            .img {
                                width: 120px;
                                height: 100%;
                                margin-right: 20px;
                                flex: none;
                                position: relative;
                                @include sys-picbox(100%, 100%, cover);
                                .shadow {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    @include sys-flex();
                                    background: rgba(0, 0, 0, .4);
                                    .play {
                                        @include sys-radius-btn(32px, 32px, rgba(0, 0, 0, .7));
                                        @include sys-picbox(10px, 11px);
                                    }
                                }
                            }
                            .itemtitle {
                                flex: 1;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                .atitle {
                                    @include sys-text-hide(2);
                                    @include sys-font(18px, #000);
                                    line-height: 20px;
                                    transition: color .3s;
                                }
                                .time {
                                    @include sys-text-hide(1);
                                    @include sys-font(16px, #999);
                                    line-height: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // .qr-c{
    //     text-align:center;

    //     div{
    //         text-align: center;
    //     }

    // }
    // .qrcode {
    //     position: fixed;
    //     width: 120px;
    //     height: 150px;
    //     bottom: 60px;
    //     right: 90px;
    //     background: #3F65E0;
    //     @include sys-picbox(130px, 191px);
    // }
}
</style>